import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import EditFormGroupFields from './EditFormGroupFields';
import {
    filterFields,
    getFieldValuesChanged,
    getFilesChanged,
    isFormValueType,
    removeField,
    removeHiddenAndDisabledFields,
    revalidateAllFormFields,
    setFieldValidations,
} from './utils/helpers';
import EditFormActions from './EditFormActions';
import {
    EditFieldInterface,
    EditFieldValueFileInterface,
    GroupEditFieldInterface,
    StateInterface,
} from 'components/interfaces/GeneralInterface';
import { useTranslation } from 'utils/localization';
import { FormResponseData } from 'services/user.service';
import stylesBasicBlock from 'components/blocks/BasicDetailsBlock.module.scss';
import { MessageType, pushMessage } from 'components/layouts/Toast';
import { FieldTypeApiEnum } from 'submodules/api_middleware';
import EditFormContentWrapper from 'components/pages/request/detail/EditFormContentWrapper';
import {
    EditFormDataInterface,
    EditFormOnFieldChangeType,
    EditFormOnFieldChangeVoidType,
    EditFormWidth,
    FileStateInterface,
    MutateFieldsCbType,
    OnActionType,
    OnButtonClickType,
    OnCancelType,
    OnChangeFileType,
    OnGoBackType,
    OnSubmitData,
    OnSuccessData,
    RequiredFieldsType,
    ValidateCbObjectInterface,
} from 'components/interfaces/EditFormInterface';
import styles from './EditForm.module.scss';

interface Props {
    fields: GroupEditFieldInterface[];
    withCancelChangesBtn?: boolean;
    buttonTextKey?: string;
    cancelButtonTextKey?: string;
    centerButtons?: boolean;
    submit?: OnSubmitData;
    action?: OnActionType;
    cancel?: OnCancelType;
    onSuccess?: OnSuccessData;
    getOnFieldChange?: EditFormOnFieldChangeVoidType;
    getMutateFieldsCb?: MutateFieldsCbType;
    goBack?: OnGoBackType;
    getSideBlock?: (data: EditFormDataInterface) => ReactElement;
    getFormData?: (data: EditFormDataInterface) => void;
    center?: boolean;
    titleCenter?: boolean;
    errorPrefixKey?: string;
    noSectionPadding?: boolean;
    isCard?: boolean;
    title?: string;
    classContent?: string;
    disabled?: boolean;
    fieldsWithBorder?: boolean;
    maxWidth?: EditFormWidth;
    inActiveFields?: string[];
}

interface EditFormInterface extends Props {
    allFields: EditFieldInterface[];
    validateCbFields: ValidateCbObjectInterface;
    errors: StateInterface;
    valuesState: StateInterface;
    valuesReactDom: StateInterface;
    valuesFiles: FileStateInterface;
}

const EditFormInner = ({
    fields,
    withCancelChangesBtn,
    submit,
    action,
    cancel,
    buttonTextKey = 'general.save',
    cancelButtonTextKey = 'general.cancelChanges',
    getSideBlock,
    getFormData,
    goBack,
    getMutateFieldsCb,
    getOnFieldChange,
    onSuccess,
    centerButtons,
    center,
    titleCenter,
    errorPrefixKey,
    noSectionPadding,
    title,
    isCard,
    classContent,
    allFields,
    validateCbFields,
    errors,
    valuesState,
    valuesReactDom,
    valuesFiles,
    disabled,
    fieldsWithBorder,
    maxWidth = 60,
    inActiveFields,
}: EditFormInterface): ReactElement => {
    const { t } = useTranslation();
    const initialValues: StateInterface = useMemo(() => valuesState, []);
    const initialValuesFiles: FileStateInterface = useMemo(
        () => valuesFiles,
        []
    );

    const [filterValues, setFilterValuesInner] =
        useState<StateInterface>(valuesState);
    const [filterErrors, setFilterErrorsInner] =
        useState<StateInterface>(errors);

    const setFilterValues = (obj: StateInterface) => {
        setFilterValuesInner(obj);
    };

    const [disabledByImageUploader, setDisabledByImageUploader] =
        useState<boolean>(false);

    const [files, setFiles] = useState<FileStateInterface>(valuesFiles);
    const [error, setError] = useState<string>(null);
    const [globalValidationFailed, setGlobalValidationFailed] =
        useState<string>(null);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [removed, setRemoved] = useState<string[]>([]);
    const [fieldNameChanged, setFieldNameChanged] = useState<StateInterface>(
        {}
    );

    useEffect(() => {
        if (fields.some((group) => group.globalValidation)) {
            fields.forEach((groupField) => {
                !!groupField.globalValidation &&
                    setGlobalValidationFailed(
                        groupField.globalValidation(filterValues)
                    );
            });
        }
    }, [fields]);

    if (getMutateFieldsCb) {
        getMutateFieldsCb(setFilterValues, () => filterValues);
    }

    const onSubmit = async () => {
        setFilterErrorsInner({});
        setLoading(true);
        const _fields = removeHiddenAndDisabledFields(
            filterValues,
            fields,
            inActiveFields
        );
        if (action) {
            const actionResponse = await action(_fields);
            setLoading(false);
            return actionResponse;
        }

        setError(null);
        const response: FormResponseData = await submit(
            _fields,
            files,
            fieldValuesChanged
        );

        if (response) {
            if (response.ok || !response.error) {
                const result = await onSuccess(_fields, files, response);
                if (typeof result === 'boolean') {
                    setLoading(!result);
                } else {
                    setLoading(false);
                }
                return;
            } else {
                setLoading(false);
            }

            if (response.errors) {
                const errors = Object.keys(response.errors).reduce(
                    (errObj, key) => {
                        const REPLACE__DATA_FIELDS_PATTERN = 'data.fields.';
                        const REPLACE_DATA_PATTERN = 'data.';
                        const newKey = key
                            .replace(REPLACE__DATA_FIELDS_PATTERN, '')
                            .replace(REPLACE_DATA_PATTERN, '');
                        Object.assign(errObj, {
                            [newKey]: response.errors[`${key}`][0]
                                ? response.errors[`${key}`][0]
                                      .replace(REPLACE__DATA_FIELDS_PATTERN, '')
                                      .replace(REPLACE_DATA_PATTERN, '')
                                : 'no data',
                        });
                        return errObj;
                    },
                    {}
                );
                setFilterErrorsInner(errors);
            }

            pushMessage(
                `${
                    (errorPrefixKey ? errorPrefixKey + '_' : '') +
                    response.error
                }_message`,
                MessageType.DANGER
            );
            // setError(t('serverErrors.' + response.error));
        }
    };

    const getOnChange: EditFormOnFieldChangeType =
        (onInputChange, triggerAllValidations) => (name, value) => {
            const validate = validateCbFields[`${name}`];
            if (validate) {
                setFilterErrorsInner({
                    ...filterErrors,
                    [name]:
                        !!value || value === 0
                            ? validate(value, filterValues)
                            : undefined,
                });
            }

            let fieldValues: StateInterface = filterValues;
            if (onInputChange) {
                fieldValues = { ...onInputChange(value, filterValues) };
            } else {
                fieldValues = { ...filterValues, [name]: value };
            }

            if (fields.some((group) => group.globalValidation)) {
                fields.forEach((groupField) => {
                    !!groupField.globalValidation &&
                        setGlobalValidationFailed(
                            groupField.globalValidation(fieldValues)
                        );
                });
            }

            setFilterValues(fieldValues);
            if (triggerAllValidations) {
                revalidateAllFormFields(
                    fieldValues,
                    validateCbFields,
                    setFilterErrorsInner
                );
            }

            setFieldNameChanged({ [name]: value });
        };

    useEffect(() => {
        if (getOnFieldChange) {
            getOnFieldChange(getOnChange);
        }
    }, [fields, validateCbFields, filterValues]);

    const getOnButtonClick: OnButtonClickType = (
        onButtonClick: (state: StateInterface) => StateInterface | void
    ) => {
        const fieldValues = onButtonClick(filterValues);
        if (fieldValues) {
            setFilterValues(fieldValues);
        }
    };

    const getOnChangeFile: OnChangeFileType = (
        name: string,
        value: EditFieldValueFileInterface[]
    ) => {
        setFiles({ ...files, [name]: value });
    };

    const { fieldValuesChanged } = useMemo(
        () => getFieldValuesChanged(filterValues, initialValues),
        [filterValues]
    );

    const filesChanged = useMemo(
        () => getFilesChanged(files, initialValuesFiles),
        [files, initialValuesFiles]
    );

    const { requiredFields, unfilledRequiredFields, requiredFieldsNotFilled } =
        useMemo(() => {
            const requiredFields: RequiredFieldsType = allFields
                .filter(
                    ({ name, required, displayIfSuccess, requiredIfSuccess }) =>
                        !removed.includes(name) &&
                        (!displayIfSuccess || displayIfSuccess(filterValues)) &&
                        (!!required ||
                            (requiredIfSuccess &&
                                requiredIfSuccess(filterValues)))
                )
                .map(({ name, label, type }) => ({ name, label, type }));

            // Get list of required fields that are empty
            const unfilledRequiredFields = requiredFields.filter(
                ({ name, type }) => {
                    // File inputs
                    if (type === FieldTypeApiEnum.FILE) {
                        return (
                            !files[`${name}`] ||
                            files[`${name}`].filter((file) => !file.isDeleted)
                                .length === 0
                        );
                    }

                    // Number inputs
                    if (type === FieldTypeApiEnum.NUMBER) {
                        return (
                            !filterValues[`${name}`] &&
                            filterValues[`${name}`] !== 0
                        );
                    }

                    // All other inputs, probably string-based
                    let value = filterValues[`${name}`];
                    if (!value) return true;

                    // Use trim if possible
                    if (value?.trim) value = value?.trim();
                    return !value;
                }
            );

            const requiredFieldsNotFilled = unfilledRequiredFields.length > 0;

            return {
                requiredFields,
                unfilledRequiredFields,
                requiredFieldsNotFilled,
            };
        }, [allFields, filterValues, files, removed]);

    const fieldFailedValidation = useMemo(() => {
        return Object.keys(validateCbFields).reduce((result, key) => {
            const displayIfSuccess = allFields.find(
                ({ name }) => name === key
            ).displayIfSuccess;
            if (displayIfSuccess && !displayIfSuccess(filterValues)) {
                return result;
            }

            const value = filterValues[`${key}`];
            const validateCb = validateCbFields[`${key}`];
            if (!!value || value === 0) {
                const validationResult = validateCb(value, filterValues);
                if (validationResult) {
                    result[`${key}`] = validationResult;
                }
            }

            return result;
        }, {} as StateInterface);
    }, [filterValues, validateCbFields]);

    const formData = useMemo(() => {
        const data = {
            state: filterValues,
            stateChanged: fieldValuesChanged,
            files: files,
            filesChanged: filesChanged,
            fieldFailedValidation: fieldFailedValidation,
            requiredFieldsNotFilled: requiredFieldsNotFilled,
            disabledByImageUploader: disabledByImageUploader,
            allShownFields: allFields.filter(
                filterFields(removed, filterValues)
            ),
            disabledReason: requiredFieldsNotFilled
                ? `${t('general.missing')}: ${unfilledRequiredFields[0].label}`
                : undefined,
        };

        getFormData?.(data);

        return data;
    }, [
        filterValues,
        fieldValuesChanged,
        files,
        filesChanged,
        fieldFailedValidation,
        requiredFieldsNotFilled,
        disabledByImageUploader,
        unfilledRequiredFields,
    ]);

    const buttonsDisabled =
        disabled ||
        disabledByImageUploader ||
        Object.values(fieldFailedValidation).length > 0 ||
        !!globalValidationFailed ||
        requiredFieldsNotFilled;

    const actionButtonExists = !!submit || !!action;

    return (
        <div
            className={classNames({
                [stylesBasicBlock.spaceBetween]: !!getSideBlock,
                [styles.test]: !getSideBlock,
            })}
        >
            <div
                className={classNames(
                    styles.wrapper,
                    classContent,
                    styles[`maxWidth-${maxWidth}`]
                )}
            >
                <EditFormContentWrapper isCard={isCard} title={title}>
                    <div>
                        <EditFormGroupFields
                            groupFields={fields}
                            initialValues={initialValues}
                            filterValues={filterValues}
                            fieldNameChanged={fieldNameChanged}
                            valuesReactDom={valuesReactDom}
                            filterErrors={filterErrors}
                            noSectionPadding={noSectionPadding}
                            titleCenter={titleCenter}
                            center={center}
                            isLoading={isLoading}
                            fieldsWithBorder={fieldsWithBorder}
                            removed={removed}
                            getOnButtonClick={getOnButtonClick}
                            requiredFields={requiredFields}
                            remove={(name, onRemove) =>
                                removeField(
                                    name,
                                    onRemove,
                                    filterValues,
                                    setFilterValues,
                                    removed,
                                    setRemoved
                                )
                            }
                            files={files}
                            getOnChange={getOnChange}
                            setDisabledByImageUploader={
                                setDisabledByImageUploader
                            }
                            getOnChangeFile={getOnChangeFile}
                            actionButtonExists={actionButtonExists}
                            buttonsDisabled={buttonsDisabled}
                            onSubmit={onSubmit}
                        />
                        <EditFormActions
                            centerButtons={centerButtons}
                            withCancelChangesBtn={withCancelChangesBtn}
                            isLoading={isLoading}
                            setLoading={setLoading}
                            cancelButtonTextKey={cancelButtonTextKey}
                            buttonTextKey={buttonTextKey}
                            goBack={goBack}
                            submit={submit}
                            action={action}
                            cancel={cancel}
                            fields={fields}
                            inActiveFields={inActiveFields}
                            filterValues={filterValues}
                            buttonsDisabled={buttonsDisabled}
                            globalValidationFailed={globalValidationFailed}
                            onSubmit={onSubmit}
                            requiredFieldsNotFilled={requiredFieldsNotFilled}
                            unfilledRequiredFields={unfilledRequiredFields}
                            actionButtonExists={actionButtonExists}
                            error={error}
                        />
                    </div>
                </EditFormContentWrapper>
            </div>
            {getSideBlock && getSideBlock(formData)}
        </div>
    );
};

interface MemoInterface {
    allFields: EditFieldInterface[];
    validateCbFields: ValidateCbObjectInterface;
    errors: StateInterface;
    valuesState: StateInterface;
    valuesReactDom: StateInterface;
    valuesFiles: FileStateInterface;
}

const EditForm = (props: Props): ReactElement => {
    const { t } = useTranslation();

    const {
        allFields,
        validateCbFields,
        errors,
        valuesState,
        valuesReactDom,
        valuesFiles,
    }: MemoInterface = useMemo(() => {
        const allFields: EditFieldInterface[] = [];
        const valuesState: StateInterface = {};
        const valuesReactDom: StateInterface = {};
        const valuesFiles: StateInterface = {};
        const validateCbFields: ValidateCbObjectInterface = {};

        props.fields.forEach((fieldGroup) => {
            fieldGroup.fields.forEach((field) => {
                const isFieldDisabled =
                    props.inActiveFields?.includes(field.name) || false;

                if (isFieldDisabled) {
                    field.validate = undefined;
                    field.required = false;
                    field.disabled = true;
                }

                allFields.push(field);
                if (field.type === FieldTypeApiEnum.FILE) {
                    valuesFiles[field.name] = field.value || [];
                    return;
                } else if (field.type === FieldTypeApiEnum.REACT_DOM) {
                    valuesReactDom[field.name] = field.value || <></>;
                    return;
                }
                if (!isFormValueType(field.type)) return;
                if (field.type === FieldTypeApiEnum.TABLE_ENTITIES) {
                    valuesState[field.name] = field.tableEntities.initData;
                } else {
                    if (
                        field.value ||
                        field.value === 0 ||
                        field.value === '0' ||
                        field.value === false
                    ) {
                        valuesState[field.name] = field.value;
                    }
                }

                setFieldValidations(
                    t,
                    field,
                    validateCbFields,
                    isFieldDisabled
                );
            });
        });
        const errors: StateInterface = {};
        props.fields.forEach((fieldGroup) => {
            fieldGroup.fields.forEach((field) => {
                if (!isFormValueType(field.type)) return;
                errors[field.name] =
                    validateCbFields[field.name] &&
                    (!!field.value || field.value === 0 || field.value === '0')
                        ? validateCbFields[field.name](
                              field.value as string,
                              valuesState
                          )
                        : undefined;
            });
        });

        return {
            allFields,
            validateCbFields,
            errors,
            valuesState,
            valuesReactDom,
            valuesFiles,
        };
    }, [props.fields]);

    return (
        <EditFormInner
            {...props}
            allFields={allFields}
            validateCbFields={validateCbFields}
            errors={errors}
            valuesState={valuesState}
            valuesFiles={valuesFiles}
            valuesReactDom={valuesReactDom}
        />
    );
};

export default EditForm;
